<template>
  <q-dialog v-model="isShowDialog" persistent>
    <q-card class="dialog" v-if="!isForScheduleLiveClass">
      <q-card-section class="row justify-between items-center">
        <h3 class="dialog-title">Nova aula ao vivo</h3>
        <q-btn color="default-pink" icon="close" flat round @click="close" />
      </q-card-section>
      <hr />
      <q-card-section>
        <div class="dialog-input">
          <label for="nameLive" class="label-input">Nome da aula</label>
          <q-input
            standout="bg-grey-13"
            v-model="dataLive.nameLive"
            for="nameLive"
            type="text"
            color="default-pink"
            placeholder="Digite aqui"
          />
          <!-- <q-toggle
            v-model="dataLive.notificationStudent"
            color="default-pink"
            label="Notificar os alunos do curso sobre a nova aula ao vivo"
            left-label
          /> -->
        </div>
      </q-card-section>
      <q-card-actions align="between">
        <SecundaryButton label="Cancelar" @onClick="close" />
        <div class="row">
          <q-btn
            color="default-pink"
            icon="schedule"
            @click="
              () => {
                isForScheduleLiveClass = true;
              }
            "
            flat
            round
            class="q-mr-sm"
          />
          <PrimaryButton
            label="Criar aula ao vivo"
            @onClick="createLive"
            :disable="dataLive.nameLive === ''"
          />
        </div>
      </q-card-actions>
    </q-card>
    <q-card class="dialog-for-schedule" v-else>
      <q-card-section>
        <h3 class="dialog-for-schedule-title">Agendar aula ao vivo</h3>
      </q-card-section>
      <q-card-section>
        <div class="form-group">
          <div class="form-group-input">
            <label class="form-group-label">Data</label>
            <q-input
              standout="bg-grey-13"
              v-model="dataLive.startDate"
              type="date"
              color="default-pink"
              placeholder="Digite aqui"
            />
          </div>
          <div class="form-group-input">
            <label class="form-group-label">Hora</label>
            <q-input
              standout="bg-grey-13"
              v-model="dataLive.time"
              type="time"
              color="default-pink"
              placeholder="Digite aqui"
            />
          </div>
        </div>
      </q-card-section>
      <q-card-actions align="between">
        <TertiaryButton label="Cancelar" @onClick="close" medium />
        <PrimaryButton
          label="Agendar"
          @onClick="isForScheduleLiveClass = false"
          medium
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
/* VUE */
import { reactive, ref, watch } from "vue";

/* COMPONENTS */
import TertiaryButton from "@/components/liveClass/comumBtn/TertiaryButton.vue";
import PrimaryButton from "@/components/liveClass/comumBtn/PrimaryButton.vue";
import SecundaryButton from "@/components/liveClass/comumBtn/SecundaryButton.vue";
import TrailDataService from "@/services/TrailDataServices";
import { date, useQuasar } from "quasar";

export default {
  name: "DialogCreateLiveClass",
  emits: ["close", "success"],
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    trainingId: Number,
    courseId: Number,
  },
  components: {
    PrimaryButton,
    SecundaryButton,
    TertiaryButton,
  },
  setup(props, { emit }) {
    /* VARIABLES */
    const isForScheduleLiveClass = ref(false);
    const trailDataService = new TrailDataService();
    const $q = new useQuasar();

    /* CONSTS */
    const dataLive = reactive({
      nameLive: "",
      notificationStudent: true,
      startDate: "",
      time: "",
    });
    const isShowDialog = ref(props.showDialog);

    /* FUNCTIONS */

    function close() {
      dataLive.nameLive = "";
      dataLive.notificationStudent = true;
      dataLive.startDate = "";
      dataLive.time = "";
      isForScheduleLiveClass.value = false;
      emit("close");
    }

    async function createLive() {
      const formData = new FormData();
      const fullTime = date.formatDate(
        `${dataLive.startDate} ${dataLive.time}`,
        "YYYY-MM-DD H:mm"
      );

      formData.append("Title", dataLive.nameLive);

      formData.append("TrainingId", props.trainingId);

      formData.append("CourseId", props.courseId);

      if (dataLive.time && dataLive.startDate) {
        formData.append("ScheduleDate", fullTime);
      } else {
        formData.append(
          "ScheduleDate",
          date.formatDate(new Date(), "YYYY-MM-DD H:mm")
        );
      }

      await trailDataService
        .createLive(formData)
        .then((response) => {
          if (response.data) {
            emit("success");
            close();
          }
        })
        .catch((error) => {
          $q.notify({
            message: `Esse erro pode ter sido causado por um problema com o sistema ou com a conta do usuário. <br/>
          Por favor, tente novamente mais tarde. Se o erro persistir, informe o seu administrador.`,
            type: "negative",
            html: true,
            caption: `${error?.message},<br/> ${error?.response?.data}`,
            position: "top",
          });
        });
    }

    watch(
      () => props.showDialog,
      (newState) => {
        isShowDialog.value = newState;
      }
    );
    return {
      /* CONSTS */
      dataLive,
      /* VARIABLES */
      isForScheduleLiveClass,
      isShowDialog,
      /* FUNCTIONS */
      close,
      createLive,
    };
  },
};
</script>

<style scoped lang="scss">
.dialog {
  font-family: "Montserrat";
  width: 630px;
  max-width: 80vw;

  &-title {
    color: var(--grayscale-grayscale-90, #1a1a1a);
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */
    letter-spacing: -0.01563rem;
  }

  .label-input {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem; /* 150% */
    letter-spacing: -0.01563rem;

    &::after {
      content: " *";
      color: var(--utilitrios-danger-light-danger-20-light, #c00);
    }
  }
}

.dialog-for-schedule {
  font-family: Montserrat;
  width: 338px;
  max-width: 80vw;
  &-title {
    color: var(--grayscale-grayscale-85, #262626);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 150% */
    letter-spacing: -0.01563rem;
  }

  .form-group {
    display: flex;
    gap: 24px;
    flex-direction: column;
    &-label {
      color: var(--grayscale-grayscale-70, #666);
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5rem; /* 150% */
      letter-spacing: -0.01563rem;
    }
  }
}
</style>
